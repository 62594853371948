<template>
    <div class="website">
        <Header></Header>
        <div class="banner">
            <img src="../static/website/website-banner.png">
        </div>
        <!--   服务方向     -->
        <div class="website-service">
            <Title title="Service Process"
                   subTitle="一站式服务体系"
                   minTitle="一站式的品牌数字服务体系，助力品牌全流程价值提升"
            ></Title>
            <div class="service-box">
                <div class="service-item">
                    <img src="../static/website/website-01.png">
                    <div class="service-con">
                        <h1>开发</h1>
                        <span>
                            优秀的技术开发让网站<br>
                            有用整体性能及扩展性<br>
                            更加优越<br>
                        </span>
                    </div>
                </div>
                <div class="service-item">
                    <img src="../static/website/website-02.png">
                    <div class="service-con">
                        <h1>接口</h1>
                        <span>
                            支持多接口程序对接及<br>
                            单点登录让网站的扩展<br>
                            性更佳<br>
                        </span>
                    </div>
                </div>
                <div class="service-item">
                    <img src="../static/website/website-03.png">
                    <div class="service-con">
                        <h1>测试</h1>
                        <span>
                            单元测试集成测试冒烟<br>
                            测试三重测试保障让网<br>
                            站更完善<br>
                        </span>
                    </div>
                </div>
                <div class="service-item">
                    <img src="../static/website/website-04.png">
                    <div class="service-con">
                        <h1>数据</h1>
                        <span>
                            原始数据导入新站数据<br>
                            添加网站内容无忧一站<br>
                            式服务<br>
                        </span>
                    </div>
                </div>

                <div class="service-item">
                    <img src="../static/website/website-05.png">
                    <div class="service-con">
                        <h1>咨询</h1>
                        <span>
                                我们为您提供一对一的<br>
                                专业咨询服务及定制化<br>
                                的建站方案<br>
                            </span>
                    </div>
                </div>
                <div class="service-item">
                    <img src="../static/website/website-06.png">
                    <div class="service-con">
                        <h1>策划</h1>
                        <span>
                                多年经验的商务经理及<br>
                                产品经理为您出谋划策<br>
                                实现产品价值<br>
                            </span>
                    </div>
                </div>
                <div class="service-item">
                    <img src="../static/website/website-07.png">
                    <div class="service-con">
                        <h1>设计</h1>
                        <span>
                                原创设计为您精准需求<br>
                                匹配定制专属服务让品<br>
                                牌脱颖而出<br>
                            </span>
                    </div>
                </div>
                <div class="service-item">
                    <img src="../static/website/website-08.png">
                    <div class="service-con">
                        <h1>制作</h1>
                        <span>
                                让智慧解手可及让便利<br>
                                无处不在多终端适配全<br>
                                站自适应浏览<br>
                            </span>
                    </div>
                </div>
            </div>
        </div>

        <!--   我们的优势     -->
        <div class="website-advantage">
            <Title title="Our advantage"
                   subTitle="我们的优势"
                   minTitle="多年客户的苛刻要求，锤炼经验老道的资深团队"
            ></Title>

            <img src="../static/website/our.png" class="advantage-img">
            <div class="advantage-con">
                 <div class="advantage-item">
                     <h1>资深技术积淀</h1>
                     <span>经验丰富的资深团队，完全定制</span>
                 </div>
                <div class="advantage-item">
                    <h1>团队经验丰富</h1>
                    <span>各行业互联网解决方案能力，让互联网更理解客户</span>
                </div>
                <div class="advantage-item">
                    <h1>金牌设计</h1>
                    <span>金牌网站设计以客户为中心，正版授权</span>
                </div>
                <div class="advantage-item">
                    <h1>优质项目</h1>
                    <span>项目经理全程把控，保障项目品质</span>
                </div>

            </div>

        </div>

        <!--   服务领域     -->
        <div class="website-area">
            <Title title="Service area"
                   subTitle="服务领域"
                   minTitle="提供短信、流程、码号、im、voip.视频等通信能力，全面满足企业业务需求"
            ></Title>
            <div class="website-area-con">
                <div class="area-item">
                    <img src="../static/website/website-service-01.png">
                    <h1>集团/上市公司</h1>
                    <span>提升集团 品牌形象</span>
                </div>
                <div class="area-item">
                    <img src="../static/website/website-service-02.png">
                    <h1>医疗/健康/生物</h1>
                    <span>提供一站式 解决方案</span>
                </div>
                <div class="area-item">
                    <img src="../static/website/website-service-03.png">
                    <h1>建筑/装饰/装修</h1>
                    <span>建筑装饰 解决方案</span>
                </div>
                <div class="area-item">
                    <img src="../static/website/website-service-04.png">
                    <h1>金融/投资</h1>
                    <span>建筑装饰 解决方案</span>
                </div>
                <div class="area-item">
                    <img src="../static/website/website-service-05.png">
                    <h1>旅游/汽车</h1>
                    <span>旅游汽车 解决方案</span>
                </div>
                <div class="area-item">
                    <img src="../static/website/website-service-06.png">
                    <h1>教育培训</h1>
                    <span>教育培训 品牌形象</span>
                </div>
                <div class="area-item">
                    <img src="../static/website/website-service-07.png">
                    <h1>文创/影视/传媒</h1>
                    <span>文创传媒 解决方案</span>
                </div>
                <div class="area-item">
                    <img src="../static/website/website-service-08.png">
                    <h1>电子科技</h1>
                    <span>电子科技 解决方案</span>
                </div>
                <div class="area-item">
                    <img src="../static/website/website-service-09.png">
                    <h1>能源环保</h1>
                    <span>能源环保 解决方案</span>
                </div>
                <div class="area-item">
                    <img src="../static/website/website-service-10.png">
                    <h1>工农业领域</h1>
                    <span>工业农业 解决方案</span>
                </div>
            </div>
        </div>

        <!--底部图片-->
        <BottomImage></BottomImage>
        <Footer></Footer>
    </div>

</template>

<script>
    export default {
        name: "website"
    }
</script>

<style scoped lang="stylus">
.website
    width 100%

    .banner
        width 100%
        height 500px
        margin-top 80px

        img
           width 100%
           height 100%

    .website-service
       display flex
       flex-direction column
       align-items center
       display table/*9*/
       margin 0 auto/*9*/

       .service-box
           display flex
           flex-direction row
           flex-wrap wrap
           width 1400px
           border-left 1px solid #dbe6f1
           border-top 1px solid #dbe6f1
           box-sizing border-box
           margin 10px auto 50px
           display table/*9*/


           .service-item
                width 25%
                display flex
                flex-direction row
                border-right 1px solid #dbe6f1
                border-bottom 1px solid #dbe6f1
                box-sizing border-box
                float left/*9*/
                display table/*9*/

                img
                  width 176px
                  height 176px
                  float left/*9*/


           .service-con
                    text-align left
                    font-size 12px
                    color #999
                    margin 0  12px


                    h1
                        font-size 30px
                        color #606266
                        font-weight normal
                        margin-top 35px

    .website-advantage
        display flex
        flex-direction column
        align-items center
        padding 0px 0 50px
        background #f0f7ff


        .advantage-img
            width 1400px
            height auto
            margin-top 20px

        .advantage-con
            display flex
            flex-direction row
            width 1400px
            border 1px solid #dbe6f1
            box-sizing border-box
            border-top none
            display table/*9*/
            margin -3px auto 0/*9*/

            .advantage-item
                width 25%
                border-right 1px solid #dbe6f1
                text-align left
                box-sizing border-box
                font-size 12px
                color #999
                padding-left 30px
                padding-bottom 10px
                background #fff
                float left/*9*/

                &:last-child
                   border-right none

                h1
                  font-size 30px
                  font-weight normal
                  color #606266
                  line-height 20px




    .website-area
        display flex
        flex-direction column
        align-items center

       .website-area-con
            display flex
            flex-direction row
            width 1400px
            flex-wrap wrap
            border-left 1px solid #dbe6f1
            border-top 1px solid #dbe6f1
            box-sizing border-box
            margin 20px auto 50px
            display table/*9*/

            .area-item
               width 20%
               font-size 12px
               color #999
               border-right 1px solid #dbe6f1
               border-bottom 1px solid #dbe6f1
               box-sizing border-box
               padding 20px 0
               float left/*9*/

               img
                 width 154px
                 height 146px

               h1
                  font-size 30px
                  font-weight normal
                  color #606266
                  line-height 10px




</style>