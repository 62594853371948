<template>
    <div class="website">
        <Header></Header>
        <div class="banner">
            <img src="../static/marketing/marketing-banner.png">
        </div>

        <!--   我们的优势     -->
        <div class="website-advantage">
            <Title title="marketing"
                   subTitle="全网营销思路"
                   minTitle="互联网技术 资源 创意 执行 数据，全网整合营销服务"
            ></Title>

            <img src="../static/marketing/marketing-marketing.png" class="advantage-img">
            <div class="advantage-con">
                <div class="advantage-item">
                    <h1>品牌曝光<span>Attention</span></h1>
                    <span>抖音头条腾讯一线媒体主动海量曝光</span>
                </div>
                <div class="advantage-item">
                    <h1>品牌口碑<span>Search</span></h1>
                    <span>小程序 论坛 贴吧 百科 新闻 涵盖主流新媒体平台</span>
                </div>
                <div class="advantage-item">
                    <h1>品牌传播<span>Share</span></h1>
                    <span>自媒体运营，让客户随时可以分享传播</span>
                </div>
                <div class="advantage-item">
                    <h1>品牌购买<span>Action</span></h1>
                    <span>强占各类型搜多引擎自然名次主动吸引客户</span>
                </div>

            </div>

        </div>

        <!--   服务方向     -->
        <div class="website-service">
            <Title title="solution"
                   subTitle="营销解决方案"
                   minTitle="围绕中小企业“互联网+”的转型升级需求，全网整合营销推广方案解决方案"
            ></Title>
            <div class="service-box">
                <div class="service-item">
                    <img src="../static/website/website-01.png">
                    <div class="service-con">
                        <h1>品牌搭建</h1>
                        <span>
                            良好的用户体验提升品牌实力<br>
                            品牌展示突出优点<br>
                            吸引眼球打动客户
                        </span>
                    </div>
                </div>
                <div class="service-item">
                    <img src="../static/website/website-02.png">
                    <div class="service-con">
                        <h1>品牌曝光</h1>
                        <span>
                            运用一线媒体与自媒体<br>
                            制定品牌详细营销推广方案<br>
                            引起客户群体关注
                        </span>
                    </div>
                </div>
                <div class="service-item">
                    <img src="../static/website/website-03.png">
                    <div class="service-con">
                        <h1>精准流量 </h1>
                        <span>
                            利用大数据工具分析<br>
                            了解品牌潜在客户特征<br>
                            精准获取用户流量
                        </span>
                    </div>
                </div>
                <div class="service-item">
                    <img src="../static/website/website-04.png">
                    <div class="service-con">
                        <h1>关键词霸屏</h1>
                        <span>
                            大数据SEO优化，排名、推广、优化<br>
                            抢占各大搜索引擎首页<br>
                            提升品牌网站排名流量
                        </span>
                    </div>
                </div>

                <div class="service-item">
                    <img src="../static/website/website-05.png">
                    <div class="service-con">
                        <h1>负面公关</h1>
                        <span>
                                我针对搜索引擎显示的负面信息<br>
                                进行负面压制<br>
                                以保证品牌口碑
                            </span>
                    </div>
                </div>
                <div class="service-item">
                    <img src="../static/website/website-06.png">
                    <div class="service-con">
                        <h1>私域流量</h1>
                        <span>
                                帮助品牌通过多入口<br>
                                获取消费者流量<br>
                                累积品牌流量池
                            </span>
                    </div>
                </div>

            </div>
        </div>





        <!--底部图片-->
        <BottomImage></BottomImage>
        <Footer></Footer>
    </div>

</template>

<script>
    export default {
        name: "website"
    }
</script>

<style scoped lang="stylus">
    .website
        width 100%

        .banner
            width 100%
            height 500px
            margin-top 80px

            img
                width 100%
                height 100%

        .website-service
            display flex
            flex-direction column
            align-items center

            .service-box
                display flex
                flex-direction row
                flex-wrap wrap
                width 1400px
                margin 10px auto 50px
                justify-content space-between
                display table/*9*/

                .service-item
                    width 32%
                    display flex
                    flex-direction row
                    border 1px solid #dbe6f1
                    box-sizing border-box
                    margin-bottom 10px
                    box-shadow 0 0 5px rgba(219,230,241,1)
                    float left/*9*/
                    margin-left 1%/*9*/
                    display table/*9*/



                img
                    width 176px
                    height 176px
                    float left/*9*/

                .service-con
                    text-align left
                    font-size 12px
                    color #999
                    margin 0  12px


                    h1
                        font-size 30px
                        color #606266
                        font-weight normal
                        margin-top 35px


        .website-advantage
            display flex
            flex-direction column
            align-items center
            padding 0px 0 50px
            background #f0f7ff

            .advantage-img
                width 1400px
                height auto
                margin-top 20px

            .advantage-con
                display flex
                flex-direction row
                width 1400px
                border 1px solid #dbe6f1
                box-sizing border-box
                border-top none
                display table/*9*/
                margin -3px auto 0/*9*/

                .advantage-item
                    width 25%
                    border-right 1px solid #dbe6f1
                    text-align left
                    box-sizing border-box
                    font-size 12px
                    color #999
                    padding-left 30px
                    padding-bottom 10px
                    background #fff
                    float left/*9*/

                    &:last-child
                        border-right none

                    h1
                        font-size 30px
                        font-weight normal
                        color #606266
                        line-height 20px
                        display flex
                        flex-direction row
                        justify-content space-between

                        span
                           font-size 14px
                           margin-right 20px
                           text-transform uppercase
                           color #999









</style>