<template>
    <div class="case-box">
        <Header></Header>
        <ProductTop></ProductTop>

        <!--   产品列表     -->
        <div class="case-content">
            <div class="case-list">
                <router-link class="case-item" v-for="(item, index) in caseList" :key="index" :to="{path:'/productDetail', query:{name:item.name}}">
                    <el-image :src="item.image" ></el-image>
                    <div class="case-txt">
                        <h1>{{item.name}}</h1>
                        <span>{{item.subTitle}}</span>
                    </div>
                </router-link>
            </div>
        </div>


        <!--底部图片-->
        <BottomImage></BottomImage>
        <Footer></Footer>
    </div>

</template>

<script>
    import caseDetail from './caseDetail.js'
    export default {
        name: "Product",
        data(){
            return{

                caseList:caseDetail
            }
        }
    }
</script>

<style scoped lang="stylus">
>>>.el-menu-item
      &:nth-child(4)
         border-bottom 3px solid #409eff !important

.case-box
    width 100%


    .case-content
        display flex
        justify-content center
        margin 30px auto 0
        display table/*9*/


    .case-list
        display flex
        flex-direction row
        align-items center
        flex-wrap wrap
        width 1400px
        justify-content space-between
        display table\9


        .case-item
           width 23.5%
           border 1px solid #dbe6f1
           box-sizing border-box
           text-decoration none
           text-align left
           color #999
           font-size 12px
           margin-bottom 20px
           float left/*9*/
           display table/*9*/
           margin-right 1.6%/*9*/

           &:nth-child(4n+0)
               margin-right 0/*9*/

           &:hover
               opacity 0.8

           .case-txt
                margin-left 20px
               padding-bottom 10px

           h1
               font-size 18px
               color #606266
               font-weight normal
               line-height 12px

           >>>.el-image
                   height 192px
</style>