<template>
    <div class="detail-box">
        <Header></Header>
        <ProductTop :detailShow="true"></ProductTop>

        <div class="block-one" >
                <el-image :src="data.detailCon.backgroundOne" class="block-one-background" fit=" cover "></el-image>
                 <div class="block-one-con">
                     <h1>{{data.detailCon.oneTitle}}</h1>
                     <span>{{data.detailCon.subtitleOneOne}}</span><br>
                    <el-image :src="data.detailCon.oneImg" ></el-image>
                 </div>

        </div>

        <div  class="block-two">
            <div class="block-two-con">
                <div class="block-two-left">
                    <h1>{{data.detailCon.twoTitle}}</h1>
                    <span class="two-sbuTitle">{{data.detailCon.twoSubTitle}}</span>
                    <ul  class="two-block-color">
                        <li v-for="(item,index) in   data.detailCon.twoBlockColor" :key="index">
                              <span class="block-color" :style="{'background': item.colorTxt}"></span>
                              <span class="block-color-txt">{{item.colorTxt}}</span>
                        </li>
                    </ul>
                </div>
                <div class="block-two-right">
                    <el-image :src="data.detailCon.twoImg" ></el-image>
                </div>
            </div>
        </div>

        <div  class="block-three" :style="{'background':data.detailCon.blockBackground}">
             <div class="block-threeTxt">
                 <el-image :src="data.detailCon.threeImg"></el-image>
                 <div class="block-three-con">
                     <h1>{{data.detailCon.threeTxt}}</h1>
                     <span>{{data.detailCon.threeSubTxt}}</span>
                 </div>
             </div>
        </div>

        <div class="block-four">
            <h1>{{data.detailCon.fourTxt}}</h1>
            <span>{{data.detailCon.fourSubTxt}}</span>
            <el-image :src="data.detailCon.fourImg" ></el-image>
        </div>

        <Footer></Footer>
    </div>
</template>

<script>
    import caseDetail from './caseDetail.js'
    import axios from 'axios'
    export default {
        name: "productDetail",
        data(){
            return{
                data: ""
            }
        },
        created() {
            let detail = caseDetail.find(item => item.name === this.$route.query.name)
            this.data = detail
        }
    }
</script>

<style scoped lang="stylus">
    .detail-box
         width 100%
    .block-one
        width 100%
        height 600px
        position relative

        .block-one-background
             display block
             width 100%
             height 100%


        .block-one-con
              width 100%
              display flex
              flex-direction column
              align-items center
              position absolute
              top  40px
              z-index 2
              color #999

              >>>.el-image
                width 670px
                height 406px


              h1
                 font-size 30px
                 color #222

              span
                 line-height 12px





    .block-two
        display flex
        justify-content center
        flex-direction row
        width 100%

       .block-two-con
          display flex
          flex-direction row
          width 1400px
          justify-content space-between
          margin 30px auto
          display table/*9*/

          >>>.el-image
             width 788px
             height 556px


       .block-two-left
           text-align left
           margin-top 90px
           float left


           h1
              font-size 30px

           .two-sbuTitle
               color #999
               line-height 12px

           .two-block-color
                display flex
                flex-direction row
                margin-top 50px
                padding-left 0

                li
                   list-style none
                   display flex
                   flex-direction column
                   margin-right 15px
                   align-items center
                   display table/*9*/
                   float left/*9*/

                .block-color
                    width 96px
                    height 96px
                    border-radius 50%
                    margin-bottom 10px
                    display block




    .block-three
        display flex
        flex-direction row
        align-items center
        justify-content center
        padding 40px 0 20px
        width 100%

        .block-threeTxt
            display flex
            flex-direction row
            text-align left
            width 1400px
            display table/*9*/
            margin 0 auto/*9*/

            >>>.el-image
                width 769px
                height 605px
                float left/*9*/

            .block-three-con
                margin-top 80px
                margin-left 60px
                float left/*9*/
                h1
                    font-size 30px
                    color #222
                    margin-top 120px
                    display block

                span
                   color #999
                   width 500px
                   display block

    .block-four
        display flex
        flex-direction column
        margin 50px 0

        h1
            font-size 30px
            color #222
            display block
            line-height 20px

        span
            color #999
            margin-bottom 20px
            display block
            text-align center


        >>>.el-image
            width 1145px
            height 652px
            margin 0 auto
            display block


    >>>.el-menu-item
        &:nth-child(4)
            border-bottom 3px solid #409eff !important


</style>